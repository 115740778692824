<script setup lang="ts">
import MobileAreaGuidesCountry from './partials/country.vue';
import MobileAreaGuidesDetails from './partials/details.vue';

const route = useRoute();
const guideStore = useGuidesStore()
const metaStore = useMetaStore();
const result = ref();

const fetchData = async () => {
  const page = route.query.page || 1;
  const slug = route.params.location as string;

  return await guideStore.fetchAreaGuideCities(slug);
}

result.value = await fetchData();

if (!result.value?.model) {
  showError({
    statusCode: 404,
    statusMessage: "Page Not Found"
  })
}
metaStore.setMeta(result.value?.meta);
</script>

<template>
  <LayoutAppHead></LayoutAppHead>
  <NuxtLayout name="mobile-base">
    <template #mobile-header>
      <mobile-header :title="$t('client.area_guides')">
        <template #header-left>
          <mobile-back-button></mobile-back-button>
        </template>
        <template #header-right>
          <mobile-share-button></mobile-share-button>
        </template>
      </mobile-header>
    </template>

    <template v-if="result.data && result.model === 'Country'">
      <mobile-area-guides-country :data="result.data"></mobile-area-guides-country>
    </template>
    <template v-else-if="result.data && result.model === 'Details'">
      <mobile-area-guides-details :data="result.data"></mobile-area-guides-details>
    </template>
    <template v-else>
      <div class="text-center pb-10">
        <div class="text-2xl py-12">
          {{ $t('client.no_guides') }}
        </div>
        <NuxtLink class="btn btn-primary p-3" :to="localePath({ name: 'home' })">{{ $t('client.back_to_home') }}</NuxtLink>
      </div>
    </template>

    <template #mobile-footer></template>
  </NuxtLayout>
</template>